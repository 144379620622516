export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  // {
  //   title: "Pages",
  //   icon: "HomeIcon",
  //   children: [
  //     {
  //       title: "Home",
  //       route: "home",
  //     },
  //   ],
  // },
  // {
  //   title: "FAQ",
  //   route: "faq",
  //   icon: "MoveIcon",
  // },
];
